import clsx from 'clsx';

import Button from '@ui/components/Button';
import Logo from '@ui/components/Logo';
import MainImage from '@ui/components/MainImage';
import Text from '@ui/components/Text';
import Title from '@ui/components/Title';
import { PageType } from '@ui/types';

export interface ErrorPageProps {
  headingText: string;
  text: string;
  buttonLink: string;
  buttonText: string;
  type?: PageType;
}

const ErrorPage = ({
  headingText,
  text,
  buttonText,
  buttonLink,
  type = 'errorPage404',
}: ErrorPageProps) => (
  <>
    <div
      className={clsx(
        'relative flex w-full flex-wrap px-8',
        type === 'errorPage500' ? 'lg:max-w-[460px]' : 'lg:max-w-[400px]',
      )}
    >
      <div className="flex w-full items-center justify-center lg:justify-start">
        <Logo />
      </div>
      <div className="relative mt-[56px] flex w-full flex-wrap items-center lg:mt-[75px]">
        <Title size="regular">{headingText}</Title>
        <Text className="mt-6">{text}</Text>
      </div>
      <div className="mt-[56px] flex w-full flex-wrap items-center justify-center lg:justify-start">
        <Button size="big" link={buttonLink}>
          {buttonText}
        </Button>
        {type === 'errorPage500' && (
          <span className="mt-6 block w-full text-center font-montserrat text-[14px]/[16px] text-brand-gray-200 lg:max-w-[300px]">
            Pokud chyba přetrvává, zkuste to znovu za 30 minut. Děkujeme za
            pochopení.
          </span>
        )}
      </div>
    </div>
    <MainImage type={type} />
  </>
);

export default ErrorPage;
