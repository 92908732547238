import { ReactNode } from 'react';

import clsx from 'clsx';

export interface TextProps {
  children?: ReactNode;
  className?: string;
}

const Text = ({ children, className }: TextProps) => (
  <p
    className={clsx(
      'font-montserrat text-[24px]/[34px] font-semibold text-brand-black-100',
      className,
    )}
  >
    {children}
  </p>
);

export default Text;
