import { useEffect } from 'react';

import { NextPage } from 'next';

import useReloadHeaderBidding from '@adverts/hooks/useReloadHeaderBidding';
import useSasicAttributes from '@adverts/hooks/useSasicAttributes';
import useGaPage from '@analytics/hooks/useGaPage';
import ErrorPage from '@errorPage/components/ErrorPage';
import Footer from '@footer/components/Footer';
import Meta from '@seo/components/Meta';
import LayoutFullPageWrapper from '@ui/components/LayoutFullPageWrapper';

const Custom404: NextPage = () => {
  const logGaPage = useGaPage();

  useEffect(() => {
    logGaPage('404');
  }, [logGaPage]);

  useReloadHeaderBidding();
  useSasicAttributes({
    keyword: ['404'],
  });

  return (
    <>
      <Meta
        title="404 | Půjdu.cz"
        description="Půjdu.cz"
        noIndex={true}
        noFollow={true}
      />
      <LayoutFullPageWrapper>
        <ErrorPage
          headingText="Vypadá to, že tu nikdo není."
          text="Stránku nelze najít. Zkontrolujte adresu, nebo vytvořte novou událost."
          buttonText="Naplánovat akci"
          buttonLink="/e"
          type="errorPage404"
        />
        <Footer className="mt-8 lg:mt-60" />
      </LayoutFullPageWrapper>
    </>
  );
};

export default Custom404;
